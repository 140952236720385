import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs } from 'antd';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { AddOrUpdateCashFlowProject, AddOrUpdateInputProject, DeleteDetails, generateEMEAASales, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import { countries_List } from '../TrackRecord/DatabseList.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ProjectsInputs = (props) => {

    const { projectDetails, setProjectDetails, setInputData, inputData, OnGenerateCashFlow } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [isRawListLoading, setisRawListLoading] = useState(false);

    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');
    const [activeKeyLine, setActiveKeyLine] = useState('INPUT')
    const [loading, setloading] = useState(false);
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    const [cashReceipts, setCashReceipts] = useState([])
    const [expenditureData, setExpenditureData] = useState([])
    const [formData, setFormData] = useState(projectDetails)
    const [newItem, setNewItem] = useState('');


    const tableNames = {
        KEY_ASSUMPTION: 'Key Assumption',
        INPUT: 'Input Projects',
        expenditure: 'Expenditure',
        cash_receipts: 'Cash Receipts',
        dashBoard: 'DashBoard'
    };

    useEffect(() => {
        console.log('rawDataList updated:', inputData);

        if (inputData)
            handleTabChange('INPUT');
    }, [inputData])





    const reorderObjectExpenture = async (obj, key) => {
        // Static keys in the desired order
        let staticKeys = ["ID", "Section", "Category", "Type"];

        // Extract dynamic date-like keys using regex
        const dateKeys = Object.keys(obj).filter((key) => /\d{2}-[A-Za-z]{3}-\d{4}/.test(key));

        // Sort the date keys chronologically
        dateKeys.sort((a, b) => new Date(a) - new Date(b));

        // Extract `Revenue_*` and `Cost_*` keys dynamically
        const revenueAndCostKeys = Object.keys(obj).filter((key) => /^Revenue_\d+$|^Cost_\d+$/.test(key));

        // Sort `Revenue_*` and `Cost_*` keys by their numeric suffix
        revenueAndCostKeys.sort((a, b) => {
            const numA = parseInt(a.split("_")[1], 10);
            const numB = parseInt(b.split("_")[1], 10);
            return numB- numA;
        });

        // Determine slice value for static keys
        const sliceV = key === "cash_receipts" ? 6 : 3;

        // Combine keys: static keys, sorted date keys, and `Revenue_*`/`Cost_*` keys
        const orderedKeys = [
            ...staticKeys.slice(0, sliceV),
            ...dateKeys,
            ...staticKeys.slice(sliceV),
            ...revenueAndCostKeys, // Always place Revenue_* and Cost_* keys at the end
        ];

        // Create a new object with the desired key order
        const reorderedObj = {};
        orderedKeys.forEach((key) => {
            if (key in obj) {
                reorderedObj[key] = obj[key];
            }
        });

        return reorderedObj;
    };


    const generateInputColumns = async (columnList, tapName) => {


        columnList = await reorderObjectExpenture(columnList, tapName)
        console.log(columnList)
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'totalCount') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly
                minWidth: ['ID'].includes(key) ? 100 : 200,

                cellEditor: ['Section', 'Category'].includes(key) ? "agSelectCellEditor" : null,
                cellEditorParams: ['Section'].includes(key) ? {
                    values: ["Commission", "Consumables & Spares", "Equipment", "PM", "Shipping Mob/Demob", "Welding Wire"],
                } : ['Category'].includes(key) ? {
                    values: ["Bands", "Beveling", "Commission", "Complete P625 stations", "IWM", "Spares & Consumables", "Shipping", "Welding Wire"],
                } : null,


                valueFormatter: (params) => {

                    {
                        if (/^(Revenue|Cost)_\d+$/.test(key)) {
                            const formattedValue = Math.floor(Number(params.value))
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return `$${formattedValue}`;
                        }
                    }


                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: { textAlign: 'left' }, // Cell alignment
                editable: true, // Enable editing for cells
                // filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },

            }));


        columnDefs.push({
            headerName: 'Actions',
            field: 'action',
            pinned: 'right',
            width: 100,
            cellRenderer: (params) => {
                const record = params.data;
                return (
                    <div>

                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>

                    </div>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });
        // Debugging output for generated columns
        //console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };

    const fetchData = async (data, key) => {
        console.log(data.length)
        if (data.length > 0) {

            generateInputColumns(data[0], key)

            setRowData(data);

            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };

    const convertToAssumptionArray = (inputObj) => {
        // Map each key-value pair into the desired object format, excluding certain keys
        return Object.entries(inputObj)
            .filter(([key]) => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'InputProjectData' && key !== 'ID') // Filter out unwanted keys
            .map(([key, value]) => ({
                Assumption_Key: key.replace(/_/g, ' '), // Replace underscores with spaces
                Value: value
            }));
    };


    const handleTabChange = async (key) => {

        //console.log(key)
        setloading(true)


        setActiveKeyLine(key);
        //setRowData([])
        //    if (key === 'INPUT')
        {

            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)
            await fetchData(inputData, key)

            setloadingData(false);
        }


        setSelectedMatrialClass(key);
        setloading(false)
    }
        

    // useEffect(() => {
    //     console.log('rawDataList updated:', rawDataList.length);

    //     if (rawDataList[activeKeyLine])
    //         fetchData();
    // }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            floatingFilter: true,
            resizable: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
            filter: true,
        };
    }, []);


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log("params", params.api)
        setGridApi(params.api)
        console.log("params", params.api)
        if (loadingData) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [loadingData, activeKeyLine]);



    // useEffect(() => {
    //     //console.log(activeKeyLine)
    //     if (gridRef.current) {
    //         console.log(activeKeyLine)
    //         if (loadingData) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [loadingData, activeKeyLine])

    //

    useEffect(() => {
        if (loadingData) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (loadingData && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);


    const OnGenerateCashFlow2 = async () => {
        setIsGenerateLoading(true)
        await OnGenerateCashFlow()
        setIsGenerateLoading(false)
    }

    // Custom status panel component
    const CustomStatusPanel = () => {
        return (
            <>

                <div style={{  display:'flex',paddingLeft:'10px' ,paddingTop: '10px' }}>
                    <Button shape="round" key="apply" type="primary" style={{ display:'flex', marginBottom: '-20px'  }}
                        onClick={async () => await addEmptyRow()}
                    >
                       + Add New
                    </Button>

                    {/* <Button shape="round" key="apply" type="primary" style={{ marginLeft: '10px', marginBottom: '-20px' }}
                        loading={isGenerateLoading}
                        onClick={async () => await OnGenerateCashFlow2()}
                    >
                        Generate Cash Flow
                    </Button> */}

                </div>

            </>
        )

    };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: () => <CustomStatusPanel />, // React component
                align: 'right',
            },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);




    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };




    function onCellValueChanged(event) {
        let { colDef, newValue, data } = event;
        let field = colDef.field;
    
        // Attempt to convert to number if it's a numeric column
        if (!isNaN(newValue) && newValue !== "") {
            newValue = Number(newValue);
        }
    
        // Update the object with the new value
        data[field] = newValue;
    
        console.log(`Updated ${field}:`, newValue);
    }

    // function onRowValueChanged(event) {
    //     const data = event.data;
    //     console.log(
    //         "onRowValueChanged: (" +
    //         data.make +
    //         ", " +
    //         data.model +
    //         ", " +
    //         data.price +
    //         ", " +
    //         data.field5 +
    //         ")",
    //     );
    // }

    // Function to Add an Empty Row
    const addEmptyRow = async () => {

        const newRow = {
            'ID': gridRef.current.api.getDisplayedRowCount() + 1, // Get index of the last row,
            "Section": "",
            "Category": "",
            "Type": "",
            "Revenue_1": 0,
            "Cost_1": 0
        }; // Define the empty row
        console.log('Emty Row', newRow)
        const transaction = { add: [newRow] }; // Create a row transaction
        gridRef.current.api.applyTransaction(transaction); // Apply the transaction
        inputData.push(newRow)
        setInputData(inputData)
        setRowData(inputData)
        gridApi.showLoadingOverlay();
        // Optionally, scroll to the new row
        setTimeout(() => {
            const rowIndex = gridRef.current.api.getDisplayedRowCount() - 1; // Get index of the last row
            gridRef.current.api.ensureIndexVisible(rowIndex, "bottom"); // Scroll to the new row
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 50);


    }

    const removeRowById = async (targetId) => {
        // Ensure gridApi and gridRef exist
        if (!gridApi || !gridRef.current) {
            console.error("Grid API or reference is not available.");
            return;
        }

        // Clone the inputData array to avoid direct mutation
        const updatedData = [...inputData];
        console.log(updatedData)
        // Find the index of the row with the target ID
        const index = updatedData.findIndex(row => row.ID === targetId);

        // Check if the ID exists in the data
        if (index === -1) {
            console.warn(`Row with ID ${targetId} not found.`);
            return;
        }

        // Remove the row at the found index
        updatedData.splice(index, 1);

        // Reorder IDs sequentially
        const reorderedData = updatedData.map((row, idx) => ({
            ...row,
            id: idx + 1, // Assign sequential IDs starting from 1
        }));

        // Update the state with the new data
        setInputData(reorderedData);
        setRowData(reorderedData);

        // Refresh the grid
        gridApi.showLoadingOverlay();
        setTimeout(() => {
            //gridRef.current.api.setRowData(reorderedData); // Update grid data
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 100); // Delay to simulate loading effect
    }

    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        if (selectedData) {
            setIsDeleteLoading(true)
            // const obj = {
            //     columnName: 'Project_Inputs',
            //     tableName: 'Project_Inputs',
            //     data: selectedData
            // };
            // console.log(obj);
            // await DeleteDetails(obj)
            await removeRowById(selectedData.ID)
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);


        }

    }
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }


    return (

        <div>


            <div style={{ height: 'calc(100vh - 270px)', overflow: 'auto', position: 'relative' }}>
                <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        onRowDragEnd={onRowDragEnd}
                        defaultColDef={defaultColDef}
                        rowSelection={rowSelection}
                        onGridReady={onGridReady}
                        sideBar={false}
                        hiddenByDefault={true}
                        pivotMode={false}
                        //
                        pivotPanelShow={"never"}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onSelectionChanged={onSelectionChanged}
                        // enableFilter={true}
                        statusBar={statusBar}
                        rowHeight={40}
                        headerHeight={50}
                        defaultExcelExportParams={defaultExcelExportParams}
                        defaultCsvExportParams={defaultCsvExportParams}
                        // loadingOverlayComponent={CustomLoadingCellRenderer}
                        // loadingOverlayComponentParams={{
                        //     loadingMessage: "Loading all rows...",
                        // }}
                        onCellValueChanged={onCellValueChanged}
                    //onRowValueChanged={onRowValueChanged}

                    />

                </div>
            </div>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

        </div>

    );


};
const mapStateToProps = ({ }) => {

    return {

    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsInputs);