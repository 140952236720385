/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Row,
    Tabs,
    Tooltip,
    Typography,
    DatePicker, Input, notification, Switch, Select, Collapse, Radio, Modal, Checkbox,
    Spin,
    Layout,
    Drawer,
    Space
} from 'antd';
import {
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    ReloadOutlined, FilterOutlined, FormOutlined, FileExcelOutlined

} from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//import { CopperShoe, DiscountRate, MasterNonSizeDependent, MasterSizeDependentParts} from './Database';
import history from '../../services/history.js';
import EquipmentsForm from './EquipmentsForm.js';
import { input } from 'aws-amplify';
import { AddOrUpdateProject, AddOrUpdateReportUrl, GenerateExcelReport, generateSummary, getListFromDatabase, RegenerateSummary } from '../../redux/Pricing/saga.js';

import { Content, Footer } from 'antd/lib/layout/layout';
import Equipment from './Equipment.js';
import DetailCalculationForm from './DetailCalculationForm';
import CalcualtedPrice from './CalculatedPrice';
import EquipmentTable from './EquipmentTable';
import DrawerPage from './DrawerPage.js';
import RevenueChart from './RevenueChart.js';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

//const { Option } = Select;
const PricingUpdateForm = (props) => {

    const {

        setShowPricingPage,
        showPricingPage,
        projectDetails,
        setProjectDetails,
        isEdit,
        setIsEdit,
        editRecord,
        categoryListdata,
        diameterList,
        setCategoryListdata

    } = props;
    const [projectDetailForm] = Form.useForm()
    // console.log("categoryListdata", categoryListdata)
    //collapse
    const [isEditProjectQuoteNo, setIsEditProjectQuoteNo] = useState(false);
    const [isEditProjectDate, setIsEditProjectDate] = useState(false);
    const [isEditProjectName, setIsEditProjectName] = useState(false);
    const [isEditProjectType, setIsEditProjectType] = useState(false);
    const [isEditProjectLocation, setIsEditProjectLocation] = useState(false);
    const [isEditProjectRepresentative, setIsEditProjectRepresentative] = useState(false);
    const [isEditProjectDiameter, setIsEditProjectDiameter] = useState(false);
    const [isEditStatus, setIsEditStatus] = useState(false);
    const [isEditProjectWelds, setIsEditProjectWelds] = useState(false);
    const [isEditProjectEstimatedStart, setIsEditProjectEstimatedStart] = useState(false);
    const [isEditContractor, setIsEditContractor] = useState(false);
    const [isEditPipeGrade, setIsEditPipeGrade] = useState(false);
    const [isEditPipeLengthFeet, setIsEditPipeLengthFeet] = useState(false);
    const [isEditDailyProductionRate, setIsEditDailyProductionRate] = useState(false);
    const [isEditSpreadConfiguration, setIsEditSpreadConfiguration] = useState(false);
    const [isEditSpreads, setIsEditSpreads] = useState(false);
    const [isEditLength, setIsEditLength] = useState(false);
    const [isEditWallThickness, setIsEditWallThickness] = useState(false);
    const [isEditEquipmentType, setIsEditEquipmentType] = useState(false);
    const [isEditPowerSupplies, setIsEditPowerSupplies] = useState(false);
    const [GenerateReportLoading, setGenerateReportLoading] = useState(false);
    const [isManaulInputfeildChanged, setIsManualInputfeildChanged] = useState(false)
    const [isMarginEdited, setIsMarginEdited] = useState(false)
    const [projectUpdateloading, setProjectUpdateloading] = useState(false);
    const [ReportUrl, setReportUrl] = useState(null)
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isProjectdetailChanged, setIsProjectdetailChanged] = useState(false);
    //equipment
    const [excludedKeys, setExcludedKeys] = useState([]);
    const [GrandKeys, setGrandKeys] = useState({
        'Total Revenue': 0,
        'Total Cost': 0,
        'Gross Margin': 0,
        'Discount': 0
    });
    const statuslist = ['In Progress', 'Lost', 'Won']
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [count, setCount] = useState(1)
    //const [showGenerateModel, setShowGenerateModel] = useState(false)
    const [partsData, setPartsData] = useState('');
    const [editPipelineDatas, setEditPipelinesDatas] = useState([])
    //  const [summaryData, setSummaryData] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [CopperShoe_List, setCopperShoe_List] = useState([]);
    const [Size_Parts, setSize_Parts] = useState([]);
    const [DiscountRate, setDiscountRate] = useState([]);
    const [Non_Size_Parts, setNon_Size_Parts] = useState([]);
    const [ReGenerating, setReGenerating] = useState(false);
    const [calculatedWeldPrice, setCalculatedWeldPrice] = useState({})
    const [userWeldPrice, setUserWeldPrice] = useState({})
    //const [summaryData, setSummaryData] = useState([]);
    const [savinglaoding, setSavinglaoding] = useState(false);
    const [pipeDiaRateList, setPipeDiaRateList] = useState([])
    const [spreadConfigList, setSpreadConfigList] = useState([])
    const [calculatedSummary, setCalculatedSummary] = useState({})

    const [CommonInputslist, setCommonInputslist] = useState(

        {
            "C.I.F": 15000,
            "Tech Cost": 95.06,
            "Tech Months": 12,
            "Wire Margin": 0.42,
            "Spares Margin": 0.42,
            "Tech Constant": 30.4,
            "Number of Techs": 2,
            "Techinican Rate": 1300,
            "Wire Cost per Weld": 23.42,
            "Spare Cost per Weld": 17,
            "Number of Tech Months": 2
        }
    )

    //const [updateForm] = Form.useForm()
    const [summaryData, setSummaryData] = useState(
        {
            "Detail Calculation": {

            },
            "Manual Per Weld Prices": {
                "Mob": 0,
                "Rent": 0,
                "Spares": 0,
                "Total": 0,
                "Wire": 0

            },
            "Summary": {
                "Cost": {
                    "Grand Total": '',
                    "Mob": '',
                    "Rent": '',
                    "Spares": '',
                    "Tech Rates": '',
                    "Total Cost": '',
                    "Wire": ''
                },
                "Margin": {
                    "Mob": '',
                    "Rent": '',
                    "Tech Rates": '',
                    "Total": ''
                },
                "Revenue": {
                    "Grand Total": '',
                    "Mob": '',
                    "Rent": '',
                    "Spares": '',
                    "Tech Rates": '',
                    "Total Revenue": '',
                    "Wire": ''
                }
            }
        }

    )
    const [formData, setFormData] = useState({
        "PFM / COUMTERBORE": 0,
        "HPU": 0,
        "IWM": 0,
        "IWM Major Spares": 0,
        "CBUC": 0,
        "CBUC Spare Copper Sets": 0,
        "CBUC Major Spares": 0,
        "R/R 40FT": 0,
        "R/R 80FT": 0,
        "P-600 Stations": 0,
        "P-600 Major Spares": 0,
        "P-600 Spare Bugs": 0,
        "M-300C Stations": 0,
        "M-300C Major Spares": 0,
        "M-300C Spare Bugs": 0,
        "Project Suppt. Cost": 0,
        "A/BAND": 0,
        "P/BAND": 0,
        "WHS Container": 0,
        "Spares & Tools": 0,
        "Project WPQ Cost": 0,
        "Cold Weather Pkg": 0,
        "P-625 Stations": 0,
        "P-625 Major Spares": 0,
        "P-625 Spare Bugs": 0,
        "Welding Shelters": 0,
        "Welding Shelters Tie-In": 0,
        "M-500 Station": 0,
        "M-500 Spare Bugs": 0,
        "M-500 Major Spare": 0,
        'Miller XMT 350 Power Supply': 0,
        'Fronius Power Supply': 0,
        'Lincoln V350 Pro Power Supply': 0

    });

    const [checkedState, setCheckedState] = useState(
        {

            "Aligners": true,
            "Pipe": true,
            "Miller XMT 350": true,
            "Lincoln": true,
            "Fronius": true,
            "Support": true,
            "WPQ": true,
            "WHS Containers": true,
            "Spares & Tools": true,
            "Cold Weather Pkg": true,
            "Cutting Bugs": true,
            "HPU": true,
            "Pipe Facing": true,
            "P-625": true,
            "M-500": true,
            "M-300C": true,
            "P-600": true,
            "Tie in Shelters": true,
            "Shelters": true,
            "80 Feet": true,
            "40 Feet": true
        }

    );


    useEffect(() => {
        if (isEdit && projectDetails) {


            if (projectDetails.Equipment_Selection)
                setFormData(projectDetails.Equipment_Selection)
            // if (projectDetails.Equipment_Checked)
            //     setCheckedState(projectDetails.Equipment_Checked)

            //  console.log('projectDetails', projectDetails)
            if (projectDetails.Others)
                setCalculatedSummary(projectDetails.Others)
            if (projectDetails.Summary)
                setSummaryData(projectDetails.Summary)
            if (editRecord.Url)
                setReportUrl(editRecord.Url)
        }

        (async () => {

            const pipeRate = await getListFromDatabase('PipeDiaRate')

            setPipeDiaRateList(pipeRate)
            const Spread_Config_List = await getListFromDatabase('Spread_Config_List')
            // console.log(Spread_Config_List)
            setSpreadConfigList(Spread_Config_List)
            //  console.log(category)
            //const dia = await getDiameterListFromDatabse(formData.Equipment_Type);
            //setDiameterList(dia.Size_Parts)
            //  console.log(dia)

            // console.log('pipeRate', pipeRate)

            const response1 = await getListFromDatabase('CommonInputs')
            if (response1 && response1.length > 0 && response1[0].CommonInputs) {
                const commoanInput = response1[0].CommonInputs;
                setCommonInputslist(response1[0].CommonInputs)
                Object.keys(commoanInput).map((key) => {
                    const newKey = key == 'C.I.F' ? key : key.replace(/ /g, '_');
                    projectDetails[newKey] = Number(commoanInput[key])

                })
                if (commoanInput && commoanInput['C.I.F'])
                    projectDetails['C_I_F'] = commoanInput['C.I.F']
            }
        })();

    }, [])


    function replaceSpacesWithUnderscores(obj) {
        if (Array.isArray(obj)) {
            return obj.map(replaceSpacesWithUnderscores);
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                const newKey = key.replace(/ /g, '_');
                acc[newKey] = replaceSpacesWithUnderscores(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    }

    function replaceUnderscoresWithSpaces(obj) {
        if (Array.isArray(obj)) {
            return obj.map(replaceUnderscoresWithSpaces);
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                const newKey = key.replace(/_/g, ' ');
                const value = obj[key];

                // Convert string to number if it's numeric, otherwise keep it as it is
                const newValue = (typeof value === 'string' && !isNaN(value)) ? Number(value) : value;

                acc[newKey] = newValue;
                return acc;
            }, {});
        }
        return obj;
    }
    function replaceUnderscoresWithSpaces1(obj) {
        if (Array.isArray(obj)) {
            return obj.map(replaceUnderscoresWithSpaces1);
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                const newKey = key.replace(/_/g, ' ');
                const value = obj[key];

                // Check if the value is a string that can be converted to a number
                if (typeof value === 'string' && !isNaN(value) && value.trim() !== '') {
                    acc[newKey] = parseFloat(value); // Convert to number
                } else {
                    acc[newKey] = value; // Keep the value as it is
                }

                return acc;
            }, {});
        }
        return obj;
    }
    const getConvertCopperShoe_List = (inputArray) => {
        const outputObject = inputArray.reduce((acc, item) => {
            const { Diameter, ...rest } = item;
            if (!acc[Diameter]) {
                acc[Diameter] = [];
            }
            acc[Diameter].push(rest);
            return acc;
        }, {});
        return outputObject
    }

    const convertSizepartList = (inputArray) => {
        const outputObject = inputArray.reduce((acc, item) => {
            const { Equipment_type, Diameter, ...rest } = item;

            if (!acc[Equipment_type]) {
                acc[Equipment_type] = {};
            }

            if (!acc[Equipment_type][Diameter]) {
                acc[Equipment_type][Diameter] = [];
            }

            acc[Equipment_type][Diameter].push(rest);

            return acc;
        }, {});
        return outputObject
    }
    function transformObject(obj) {
        const result = {};

        for (const [key, value] of Object.entries(obj)) {
            const newKey = key.replace(/_/g, ' '); // Replace underscores with spaces

            if (Array.isArray(value)) {
                result[newKey] = value.map(item => {
                    const newItem = {};

                    for (const [itemKey, itemValue] of Object.entries(item)) {
                        const newItemKey = itemKey.replace(/_/g, ' '); // Replace underscores with spaces
                        const convertedValue = isNaN(Number(itemValue)) ? itemValue : Number(itemValue); // Convert to number if applicable
                        newItem[newItemKey] = convertedValue;
                    }

                    return newItem;
                });
            } else if (typeof value === 'object') {
                result[newKey] = transformObject(value); // Recursively transform nested objects
            } else {
                result[newKey] = value;
            }
        }

        return result;
    }

    const convertDiscountRateList = (arrayData) => {
        const resultObject = arrayData.reduce((acc, item) => {
            acc[item.No_of_Joints] = Number(item.Discount_Factor);
            return acc;
        }, {});
        return resultObject
    }
    useEffect(() => {


        (async () => {

            const list = await getListFromDatabase('CopperShoe_List')

            const data = await replaceUnderscoresWithSpaces1(list)

            const convertList = await getConvertCopperShoe_List(data)
            const obj = [{
                'CopperShoe': convertList
            }]
            setCopperShoe_List(obj)



            const size_part_list = await getListFromDatabase('Size_Parts')

            const convertsize_part_list = await convertSizepartList(size_part_list)
            const size_part_list1 = await transformObject(convertsize_part_list)
            setSize_Parts(size_part_list1)



            const DiscountRate_list = await getListFromDatabase('DiscountRate')
            const convertDiscountRate_list = await convertDiscountRateList(DiscountRate_list)
            setDiscountRate(convertDiscountRate_list)
            // console.log('DiscountRate', convertDiscountRate_list)

            const Non_Size_Parts_list = await getListFromDatabase('Non_Size_Parts')
            const Non_Size_Parts_list1 = await replaceUnderscoresWithSpaces1(Non_Size_Parts_list)
            setNon_Size_Parts(Non_Size_Parts_list1)
            // console.log('Non_Size_Parts_list', Non_Size_Parts_list1)
        })();

    }, [])


    function replaceUnderscoresWithSpaces(obj) {
        if (Array.isArray(obj)) {
            return obj.map(replaceUnderscoresWithSpaces);
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                const newKey = key.replace(/_/g, ' ');
                acc[newKey] = replaceUnderscoresWithSpaces(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    }

    const OnGenerateReport = async () => {
        setConfirmLoading(true)

        Object.keys(CommonInputslist).map((key) => {
            const newKey = key == 'C.I.F' ? key : key.replace(/ /g, '_');
            projectDetails[newKey] = Number(CommonInputslist[key])

        })
        if (CommonInputslist)
            projectDetails['C_I_F'] = CommonInputslist['C.I.F']


        projectDetails.Equipment_Selection = formData;

        const proData = replaceUnderscoresWithSpaces(projectDetails)

        if (formData['C_I_F'] == 0)
            formData['C_I_F'] = 15000
        // if (isEdit)
        proData['C.I.F'] = Number(projectDetails['C_I_F'])
        // else
        projectDetails['C_I_F'] = proData['C.I.F']
        if (projectDetails.summaryData) {
            summaryData['Manual Per Weld Prices'] = userWeldPrice
            projectDetails.summaryData = summaryData
        }
        const data = {
            input: proData,
            CopperShoe: CopperShoe_List[0],
            DiscountRate: DiscountRate,
            MasterNonSizeDependent: Non_Size_Parts,
            MasterSizeDependentParts: Size_Parts
        }


        const summary = await generateSummary(data)
        if (summary) {
            //  console.log('summary Data:', summary)

            // if (projectDetails['Others']?.calculatedSummary?.CalculatedRevenue) {
            //     const gm = parseFloat((summary['Margin']['Total'].GM * 100).toFixed(2)) ?? 0
            //     projectDetails['Others'].calculatedSummary.CalculatedRevenue.Center.Margin = gm
            //     projectDetails['Others'].calculatedSummary.CalculatedRevenue.Center.Revenue = summary['Revenue']['Total Revenue']

            //    setCalculatedSummary(projectDetails['Others'].calculatedSummary);
            // }                    
            setSummaryData(summary)
            projectDetails.Summary = summary;
            if (!summaryData["Detail Calculation"] || Object.keys(summaryData["Detail Calculation"]).length === 0)
                await AddOrUpdateProject(projectDetails, isEdit)
            else
                await OnReGenerateSummary()
        }
        setConfirmLoading(false)
    };

    const OnReGenerateSummary = async () => {
        if (summaryData) {
            setReGenerating(true);
            summaryData['Manual Per Weld Prices'] = userWeldPrice
            projectDetails.summaryData = summaryData
            const proData = replaceUnderscoresWithSpaces(projectDetails)
            // console.log('ProejctData Pyhtn :', proData)

            proData['C.I.F'] = Number(projectDetails['C_I_F'])
            projectDetails['C_I_F'] = proData['C.I.F']


            const data = {
                input: proData,
                calculates_summary: summaryData
            }

            // console.log('Resummary Data:', data)

            const Resummary = await RegenerateSummary(data)
            if (Resummary) {
                // console.log('summary Data:', Resummary)
                summaryData.Summary = Resummary.Summary
                setSummary(Resummary.Summary)
                //summaryData['Manual Per Weld Prices'] = userWeldPrice
                setSummaryData(summaryData)
                projectDetails.Summary = summaryData;
                await AddOrUpdateProject(projectDetails, isEdit)
            }
            setIsManualInputfeildChanged(false)
            setReGenerating(false);
        }
    }
    const OnSaveSummary = async () => {
        setSavinglaoding(true)
        projectDetails.Others = calculatedSummary
        await AddOrUpdateProject(projectDetails, isEdit)
        setSavinglaoding(false)
    }

    const OnGenerateExcelReport = async () => {
        setGenerateReportLoading(true)
        projectDetails.Others = calculatedSummary
        const proData = replaceUnderscoresWithSpaces(projectDetails)



        const data = {
            input: proData,
            CopperShoe: CopperShoe_List[0],
            DiscountRate: DiscountRate,
            MasterNonSizeDependent: Non_Size_Parts,
            MasterSizeDependentParts: Size_Parts
        }
        //   console.log('ProejctData Pyhtn :', proData)
        const urlReport = await GenerateExcelReport(data)
        if (urlReport.s3Url) {
            const url = {
                ID: projectDetails.ID,
                Url: urlReport.s3Url
            }
            await AddOrUpdateReportUrl(url, isEdit)
            if (urlReport.s3Url) {
                setReportUrl(urlReport.s3Url)
                const link = document.createElement('a');
                link.href = urlReport.s3Url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                projectDetails.url = urlReport.s3Url

            }
            //console.log(urlReport)
        }
        setGenerateReportLoading(false)
    }

    const onClose = async () => {
        setIsEdit(false)
        setShowPricingPage(false)
    }
    //equipment table data 
    const [summary, setSummary] = useState({

        'Manual Per Weld Prices': {
            "Mob": '',
            "Rent": '',
            "Spares": '',
            "Total": '',
            "Wire": ''
        },

        "Cost": {
            "Grand Total": '',
            "Mob": '',
            "Rent": '',
            "Spares": '',
            "Tech Rates": '',
            "Total Cost": 0,
            "Wire": ''
        },
        "Margin": {
            "Mob": 0,
            "Rent": 0,
            "Tech Rates": '',
            "Total": 0
        },
        "Revenue": {
            "Grand Total": 0,
            "Mob": 0,
            "Rent": 0,
            "Spares": 0,
            "Tech Rates": 0,
            "Total Revenue": 0,
            "Wire": 0
        }


    })

    useEffect(() => {
        const areAllValuesZero = (obj) => {
            return Object.values(obj).every(value => value === 0);
        };

        if (!summaryData['Calculated Per Weld Prices']) {
            // If 'Calculated Per Weld Prices' is not available, use 'Manual Per Weld Prices'
            summaryData['Calculated Per Weld Prices'] = summaryData['Manual Per Weld Prices'];
            setCalculatedWeldPrice(summaryData['Manual Per Weld Prices']);

            // Only set 'User Weld Price' if 'Manual Per Weld Prices' exists and userWeldPrice has all keys with value 0 or it's an empty object
            if (summaryData['Manual Per Weld Prices'] && (Object.keys(userWeldPrice).length === 0 || areAllValuesZero(userWeldPrice))) {
                setUserWeldPrice(summaryData['Manual Per Weld Prices']);
            }

        } else {
            // If 'Calculated Per Weld Prices' is available, use it
            setCalculatedWeldPrice(summaryData['Calculated Per Weld Prices']);

            // Only set 'User Weld Price' if 'Manual Per Weld Prices' exists and userWeldPrice has all keys with value 0 or it's an empty object
            if (summaryData['Manual Per Weld Prices'] && (Object.keys(userWeldPrice).length === 0 || areAllValuesZero(userWeldPrice))) {
                setUserWeldPrice(summaryData['Manual Per Weld Prices']);
            }
        }

        // Set the summary if 'Summary' exists in summaryData
        if (summaryData['Summary']) {
            setSummary(summaryData['Summary']);
        }

        // console.log('SummaryData:', summaryData);
    }, [summaryData]);


    useEffect(() => {
        if (projectDetails.Others.calculatedSummary && calculatedSummary) {

            projectDetails['Others'] = calculatedSummary
        }

    }, [calculatedSummary]);
    useEffect(() => {
        if (summary) {

            const updatedCalculatedSummary = {
                ...projectDetails['Others'],
                totalRevenue: summary['Revenue']['Total Revenue'],
                totalCost: summary['Cost']['Total Cost'],
                GM: summary['Margin']['Total'],
            };
            setCalculatedSummary(updatedCalculatedSummary);

        }

    }, [summary]);

    const DataDisplay = ({ data }) => {

        // Function to render each row of data (label and value)
        const renderRow = (label, value) => {
            return (
                <tr key={label}>
                    <td className="label">{label}:</td>
                    <td className="value">{value}</td>
                </tr>
            );
        };

        // Function to render each section (Cost, Margin, Revenue)
        const renderSection = (sectionData, sectionTitle) => {
            return (
                <div className="section" key={sectionTitle}>
                    <h2>{sectionTitle}</h2>
                    <table>
                        <tbody>
                            {Object.entries(sectionData).map(([key, value]) => renderRow(key, value))}
                        </tbody>
                    </table>
                </div>
            );
        };

        // Extracting the Summary data once for clarity and reusability
        const { Cost, Margin, Revenue } = summaryData.Summary;

        // Sections to display
        const sections = [
            { title: 'Cost', data: Cost },
            { title: 'Margin', data: Margin },
            { title: 'Revenue', data: Revenue },
        ];

        return (
            <div className="data-display">
                {sections.map(({ title, data }) => renderSection(data, title))}
            </div>
        );
    };

    useEffect(() => {
        const summary = summaryData.Summary;
        const formatCurrency = (value) => {
            return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
        };
        //console.log('grabkey',summary)
        const items = {
            'Total Revenue': formatCurrency(summary['Revenue']['Total Revenue']),
            'Total Cost': formatCurrency(summary['Cost']['Total Cost']),
            'Gross Margin': `${(summary['Margin']['Total'] * 100).toFixed(2)}%`,
            'Discount': `${summaryData['Detail Calculation']['Discount'] * 100}%`
        };

        setGrandKeys(items);
    }, [summaryData]);



    const handleInputPojectChange = (name, value) => {
        // console.log(name, value)
        setProjectDetails(prevData => ({ ...prevData, [name]: value }));
    };
    const getPipeDiaRateValue = (dataArray, projectType, diameter) => {
        const discountFactors = dataArray
            .filter(item => item.Diameter === diameter)
            .map(item => item[projectType]);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = discountFactors.length > 0 ? Number(discountFactors[0]) : 0;
        return discountFactor
    }

    const getSpreadFactorValue = (dataArray, name) => {
        const factor = dataArray
            .filter(item => item.Spread_Config === name)
            .map(item => item['Spreads']);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = factor.length > 0 ? Number(factor[0]) : 0;
        // console.log(dataArray, discountFactor, factor)
        return discountFactor
    }
    const OnUpdateProject = async () => {
        setProjectUpdateloading(true)
        //  console.log('Updated Data', name)
        if (isProjectdetailChanged) {
            const dia = projectDetails['Pipe_OD']
            if (dia && dia != 0) {
                //formData['Pipe_OD'] = `${dia} inch`
                const Project_diameter_Rate = getPipeDiaRateValue(pipeDiaRateList, projectDetails['Project_Type'], projectDetails['Pipe_OD'])
                //pipeDiaRateList[0][formData['Project_Type']][formData['Pipe_OD']]
                //console.log('Project_diameter_Rate', Project_diameter_Rate)
                projectDetails['Project_diameter_Rate'] = Project_diameter_Rate ?? 0
            }

            const factor1 = getSpreadFactorValue(spreadConfigList, projectDetails['Spread_Config'])

            const spreadConfig =
            {
                Name: projectDetails['Spread_Config'],
                Factor: factor1
            }
            projectDetails.Spread_Configuration = spreadConfig

            const proData = replaceUnderscoresWithSpaces(projectDetails)
            //  console.log('ProejctData Pyhtn :', proData)
            if (formData['C_I_F'] == 0)
                formData['C_I_F'] = 15000
            // if (isEdit)
            proData['C.I.F'] = Number(projectDetails['C_I_F'])
            // else
            projectDetails['C_I_F'] = proData['C.I.F']


            const data = {
                input: proData,
                CopperShoe: CopperShoe_List[0],
                DiscountRate: DiscountRate,
                MasterNonSizeDependent: Non_Size_Parts,
                MasterSizeDependentParts: Size_Parts
            }
            // console.log('Data:', data)
            //   console.log('projectDetails:', projectDetails)
            //  console.log('database Object', replaceOuterSpacesWithUnderscores(projectDetails))

            const summary = await generateSummary(data)
            if (summary) {
                //  console.log('summary Data:', summary)
                setSummaryData(summary)
                projectDetails.Summary = summary;
                //projectDetails.Equipment_Checked = checkedState
                // projectDetails.Others = {}

                await AddOrUpdateProject(projectDetails, isEdit)
            }
        }
        else
            await OnSaveSummary()
        setProjectUpdateloading(false)
        closeDrawer();
    }

    const getPricingTabpanes = () => {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={5} style={{ paddingRight: '10px' }}>
                        <div
                            style={{
                                // border: "1px solid #e0e0e0", // Light border around the component
                                //  borderRadius: "8px", // Rounded corners for a smoother look
                                // backgroundColor: "transparent", // Light background color for visual separation
                                //padding: "5px", // Add padding for spacing
                                //boxSizing: "border-box",
                            }}
                        >
                            <Equipment
                                setFormData={setFormData}
                                formData={formData}
                                setCheckedState={setCheckedState}
                                checkedState={checkedState}
                                OnGenerateReport={OnGenerateReport}
                                confirmLoading={confirmLoading}
                                powerSupplies={projectDetails.Power_Supplies}
                            />
                        </div>
                    </Col>
                    <Col span={19} style={{ paddingRight: '0px', paddingLeft: '0px' }}>

                        <Layout style={{ height: '93vh', width: '100%' }}>
                            <Content >
                                <>
                                    <div
                                        style={{
                                            display: "inline",
                                            //  border: "1px solid #e0e0e0", // Border for separation
                                            borderRadius: "8px", // Rounded corners
                                            backgroundColor: "transparent", // Background color
                                            padding: "0px",
                                            boxSizing: "border-box",
                                            // alignItems: "flex-start", // Ensure top alignment between components
                                        }}
                                    >
                                        <EquipmentTable
                                            summary={summary}
                                            calculatedWeldPrice={userWeldPrice}
                                            OnSaveSummary={OnSaveSummary}
                                            savinglaoding={savinglaoding}
                                            onClose={onClose}
                                            calculatedSummary={calculatedSummary}
                                            setCalculatedSummary={setCalculatedSummary}
                                            ReportUrl={ReportUrl}
                                            setIsMarginEdited={setIsMarginEdited}
                                            summaryData={summaryData}
                                        />

                                    </div>
                                    {summaryData && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                                boxSizing: "border-box",
                                                overflowY: "hidden",
                                                position: "relative",
                                                height: "49vh",
                                                paddingTop: '10px'
                                            }}
                                        >
                                            {/* Top Section: CalcualtedPrice */}
                                            <div
                                                style={{
                                                    width: "100%",
                                                    paddingTop: "5px",
                                                    paddingBottom: "5px",
                                                    paddingRight: "5px",
                                                    border: "1px solid #e0e0e0", // Border for separation
                                                    borderRadius: "8px",
                                                    backgroundColor: "transparent",
                                                    marginBottom: "0px", // Space between sections
                                                }}
                                            >
                                                <CalcualtedPrice
                                                    calculatedWeldPrice={calculatedWeldPrice}
                                                    userWeldPrice={userWeldPrice}
                                                    setUserWeldPrice={setUserWeldPrice}
                                                    OnReGenerateSummary={OnReGenerateSummary}
                                                    ReGenerating={ReGenerating}
                                                    setIsManualInputfeildChanged={setIsManualInputfeildChanged}
                                                />
                                            </div>
                                            {/* Bottom Section: DetailCalculationForm and RevenueChart */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flex: "1",
                                                    overflowY: "hidden",
                                                    borderRadius: "8px", // Outer border with rounded corners
                                                    //  border: "1px solid #e0e0e0", // Outer border for the whole container
                                                    backgroundColor: "transparent",
                                                    paddingTop: '10px'
                                                }}
                                            >
                                                {/* DetailCalculationForm Section with its own border */}
                                                <div
                                                    style={{
                                                        flex: "1 1 40%",
                                                        overflowY: "auto",
                                                        paddingRight: "10px",
                                                        paddingTop: "0px",
                                                        borderRight: "1px solid #e0e0e0", // Border between the two sections
                                                        border: "1px solid #e0e0e0", // Individual border for this section
                                                        borderRadius: "8px", // Optional: Rounded corners for each section
                                                        marginRight: "10px", // Adds spacing between sections
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <DetailCalculationForm
                                                        summaryData={summaryData}
                                                        setExcludedKeys={setExcludedKeys}
                                                    />
                                                </div>

                                                {/* RevenueChart Section with its own border */}
                                                <div
                                                    style={{
                                                        flex: "1 1 60%",
                                                        overflowY: "auto",
                                                        paddingLeft: "10px",
                                                        boxSizing: "border-box",
                                                        paddingTop: "0px",
                                                        border: "1px solid #e0e0e0", // Individual border for this section
                                                        borderRadius: "8px", // Optional: Rounded corners for each section
                                                    }}
                                                >
                                                    <RevenueChart
                                                        calculatedSummary={calculatedSummary}
                                                        totalCost={calculatedSummary.totalCost}
                                                        grossMargin={calculatedSummary.GM}
                                                        totalRevenue={calculatedSummary.totalRevenue}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </>
                            </Content>
                            <Footer
                                className="equipment-footer"

                            >
                                {isMarginEdited && !isManaulInputfeildChanged && (
                                    <Button
                                        type="primary"
                                        disabled={!isMarginEdited}
                                        loading={savinglaoding}
                                        onClick={() => OnSaveSummary()}
                                    >
                                        Save Summary
                                    </Button>
                                )}
                                {isManaulInputfeildChanged && (
                                    <Button
                                        type="primary"
                                        disabled={!isManaulInputfeildChanged}
                                        onClick={OnReGenerateSummary}
                                        loading={ReGenerating}
                                    >
                                        Regenerate
                                    </Button>
                                )}
                                <Button
                                    type="primary"
                                    onClick={() => OnGenerateExcelReport()}
                                    loading={GenerateReportLoading}
                                >
                                    Generate Report
                                </Button>
                            </Footer>
                        </Layout>
                    </Col>
                </Row>
            </div>

        );
    }



    const handleQuarterChange = (date, dateString) => {

        setProjectDetails(prevData => ({ ...prevData, EstimatedStart: dateString })); // Update the formData with the formatted value
    };
    // console.log("projectDetails", projectDetails)


    const [selectedPowerSupplies, setSelectedPowerSupplies] = useState(
        Object.keys(projectDetails.Power_Supplies).filter((key) => projectDetails.Power_Supplies[key] === "True")
    );

    // const handlePowerSuppliesChange = (values) => {
    //     const updatedPowerSupplies = {
    //         ...projectDetails.Power_Supplies,
    //         // Set "True" for selected and "False" for unselected
    //         ...Object.keys(projectDetails.Power_Supplies).reduce((acc, key) => {
    //             acc[key] = values.includes(key) ? "True" : "False";
    //             return acc;
    //         }, {})
    //     };
    //     setSelectedPowerSupplies(values);
    //     projectDetails.Power_Supplies = updatedPowerSupplies; // Update projectDetails
    // };



    const handlePowerSuppliesChange = (supplyType, value) => {
        // Update formData's Power_Supplies object
        setProjectDetails({
            ...projectDetails,
            Power_Supplies: {
                ...projectDetails.Power_Supplies,
                [supplyType]: value,
            },
        });
    };

    const handleCancel = () => {
        setIsEditPowerSupplies(false);
    };
    const showDrawer = () => {
        // console.log(record)
        // projectDetailForm.resetFields()
        // seteditRecord(record)
        //setProjectDetails(record)
        projectDetailForm.setFieldsValue(projectDetails)
        setIsEdit(true)

        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    const downloadedFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        // link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (

        <div style={{ height: '100%' }}>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {
                            setIsEdit(false)
                            setShowPricingPage(false)
                            history.push('/Pricing');
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    Pricing
                </Title>

                {/* <div className='pricing-updateFrom' style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginLeft: '20%' }}>
                    {Object.keys(GrandKeys).map(key => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '50px', marginRight: '50px' }}>
                            <strong style={{ fontWeight: 'bold', marginBottom: '0px', color: '#ef314c' }}>{key}</strong>
                            <span style={{ fontWeight: 'bold' }}>
                                {GrandKeys[key]}
                            </span>
                        </div>
                    ))}
                </div> */}
                <div className="new-calibration">
                    {ReportUrl && ReportUrl && ReportUrl != '' &&
                        <Button shape="round" key="apply" type="primary" onClick={() => downloadedFile(ReportUrl)} style={{ marginLeft: '10px' }}>
                            <FileExcelOutlined style={{ fontSize: '18px' }}> </FileExcelOutlined >
                            Report
                        </Button>
                    }
                    <Button shape="round" key="apply" type="primary" onClick={showDrawer} style={{ marginLeft: '10px' }}>
                        <FormOutlined style={{ fontSize: '18px' }}> </FormOutlined >
                        Project Details
                    </Button>


                </div>
            </div>



            {getPricingTabpanes()}

            <Drawer
                title="Project Details"
                placement="right"
                onClose={closeDrawer}
                visible={isDrawerVisible}
                width='30%'
                className='pipeline-modal Filter-Drawer'
            >

                <div className="drawer-content" >
                    <Layout className="content-scrollable">
                        <Content>
                            <Space style={{ display: 'flow', alignItems: 'center', }}>
                                <DrawerPage
                                    projectDetailForm={projectDetailForm}
                                    categoryListdata={categoryListdata}
                                    setCategoryListdata={setCategoryListdata}
                                    formData={projectDetails}
                                    setFormData={setProjectDetails}
                                    setProjectDetails={setProjectDetails}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    editRecord={editRecord}
                                    setIsProjectdetailChanged={setIsProjectdetailChanged}
                                    diameterList={diameterList}
                                //OnUpdateProject ={OnUpdateProject}

                                >

                                </DrawerPage>
                            </Space>
                        </Content>

                    </Layout>
                    <div>
                        <Footer className="fixed-footer" >
                            <div className="button-container">

                                <Button type="primary" style={{ marginTop: 6, }} onClick={() => OnUpdateProject()} loading={projectUpdateloading}>
                                    Submit
                                </Button>
                            </div>
                        </Footer>

                    </div>
                </div>


            </Drawer >
        </div >
    );
};

const mapStateToProps = ({ sparepartsreducer }) => {

    const isGenerateLoading = get(sparepartsreducer, 'isGenerateLoading', false);
    return {
        isGenerateLoading
    }
}


const mapDispatchToProps = {
    //OnGenerateSpareParts: generateSparePartsAction,

};

export default connect(mapStateToProps, mapDispatchToProps)(PricingUpdateForm);

