import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs } from 'antd';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined, DownloadOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { AddOrUpdateCashFlowProject, AddOrUpdateInputProject, AddOrUpdateReportUrl, DeleteDetails, GenerateCashflowExcelReport, generateEMEAASales, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import { countries_List } from '../TrackRecord/DatabseList.js';
import { AgCharts } from 'ag-charts-react';
import ProjectsInputs from './ProjectsInputs.js';
import { CustomLoadingCellRenderer } from './Uitiles.js';
import ProjectDetailsAgGrid from './ProjectDetailsAgGrid.js';
import { isEditable } from '@testing-library/user-event/dist/utils/index.js';

import html2canvas from 'html2canvas';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ProjectsTaps = (props) => {

    const { rawDataList, isRawListLoading, setShowPipelineModel, showPipelineModel, projectDetails, setProjectDetails, projectDetailForm,

        isEdit,
        setIsEdit,
        editRecord,
    } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const chartRef1 = useRef(null);
    const chartRef2 = useRef(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isGenerateLoading, setIsGenerateLoading] = useState(false)
    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');
    const [activeKeyLine, setActiveKeyLine] = useState('KEY_ASSUMPTION')
    const [loading, setloading] = useState(false);
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    const [cashReceipts, setCashReceipts] = useState([])
    const [expenditureData, setExpenditureData] = useState([])
    const [formData, setFormData] = useState({
        "Name": "",
        "Country": "",
        "Pipelines_count": 1,
        "Expected_signing_date": "",
        "Start_of_job": "",
        "Leaves_port": "",
        "Arrives_port": "",
        "Production_start": "",
        "Production_end": "",
        "welding_wire_order": "",
        "Welding_wire_shipment": "",

        "Payment_terms": 30,
        "Commission": 0,
        "Payment_Milestones": {
            "1": {
                "Milestone_Text": "",
                "Percentage": '',
                "Invoice_Date": ""
            },

        },

        "Technicians": {
            "Pipeline_1": {
                Pipeline_Number:1,
                "Senior_technicians": {
                    "Quantity": 5,
                    "Day_rate": 150,
                    "Day_revenue": 750
                },
                "Lead_technicians": {
                    "Quantity": 5,
                    "Day_rate": 150,
                    "Day_revenue": 750
                },
                "Welding_crews": {
                    "Quantity": 5,
                    "Day_rate": 150,
                    "Day_revenue": 750
                }
            }
        },

        // "Senior_technicians": '',
        // "Lead_technicians": '',
        // "Senior_tech_rate": '',
        // "Lead_tech_rate": '',
        // "Senior_technicians_rev": '',
        // "Lead_technicians_rev": '',

        "Performance_Bond": {
            "Enabled": "No",
            "Performance_Bond_Text": "",
            "Cost": "Financial",
            "Percentage": '',
            "At_signing": "Yes",
            "Invoice_Date": "",
            "Techs_revenue_included": "Yes",
            "Money_back": "At project end",
            "Months": 12
        },
        "Cash_collateral": {
            "Enabled": "No",
            "Cash_collateral_Text": "",
            "Percentage": '',
            "At_signing": "Yes",
            "Invoice_Date": "",
            "Money_back_Date": "",
            "Techs_revenue_included": "Yes"
        },

        "WHT": {
            "Enabled": "No",
            "WHT_rate": '',
            "WHT_Text": "",

        },


    })
    const [newItem, setNewItem] = useState('');
    const [inputData, setInputData] = useState([
        {
            "ID": 1,
            "Section": "",
            "Category": "",
            "Type": "",
            "Revenue_1":0,
            "Cost_1":0
        }
    ]);
    const [dashBoardCashReceipts, setDashBoardCashReceipts] = useState({});
    const [dashBoardExpenditureData, setDashBoardExpenditureData] = useState({});
    const [isExpFullScreen, setIsExpFullScreen] = useState(false);
    const [isRevFullScreen, setIsRevFullScreen] = useState(false);
    const [GenerateReportLoading, setGenerateReportLoading] = useState(false);
    const tableNames = {
        KEY_ASSUMPTION: 'Project Details',
        //INPUT: 'Input Projects',
        expenditure: 'Expenditure',
        cash_receipts: 'Cash Receipts',
        dashBoard: 'Dashboard'
    };

    useEffect(() => {
        (async () => {

            console.log('Project Data',isEdit ,projectDetails)
            if (isEdit && projectDetails ) {
                // const data = {
                //     'Project_details': projectDetails,
                //     Inputs: inputData.Inputs
                // }
             console.log('Project Data',isEdit ,projectDetails)

                // const res = await generateEMEAASales(data)
                // console.log(res)
                // projectDetails['CashFlowData'] = res
                // setProjectDetails()
                setFormData(projectDetails)
                if (projectDetails['CashFlowData']) {
                    if (projectDetails['CashFlowData'].expenditure)
                        setExpenditureData(projectDetails['CashFlowData'].expenditure)
                    if (projectDetails['CashFlowData'].cash_receipts)
                        setCashReceipts(projectDetails['CashFlowData'].cash_receipts)
                }

                if (projectDetails['InputProjectData'] && projectDetails['InputProjectData'].length > 0)
                    setInputData(projectDetails['InputProjectData'])

                // else if (inputData.length == 0) {
                //     const res = await getListFromDatabase('Project_Inputs')
                //     if (res) {
                //         console.log('Input Data', res)
                //         let inputDataValue;
                //         if (formData['Pipelines_count'] > 1) {
                //             const count = formData['Pipelines_count']; // Number of pipelines
                //             const dataInput = res.map(item => ({ ...item })); // Create a shallow copy of each object in the array
                //             for (let index = 1; index < count; index++) { // Start from 1 to avoid duplicating existing tags
                //                 dataInput.forEach((item) => {
                //                     // Copy values for Revenue and Cost with the updated index
                //                     item[`Revenue_${index + 1}`] = item['Revenue_1'];
                //                     item[`Cost_${index + 1}`] = item['Cost_1'];
                //                 });
                //             }
                //             //console.log(dataInput);
                //             inputDataValue = dataInput
                //         }
                //         else {
                //             inputDataValue = res
                //         }
                //         setInputData(inputDataValue)
                //     }
                // }


                //setloading(true)


            }
            else {
                // (async () => {

                //     if (inputData.length == 0) {
                //         const res = await getListFromDatabase('Project_Inputs')
                //         if (res) {
                //             console.log('Input Data', res)
                //             setInputData(res)
                //             //formData['InputProjectData'] = res
                //         }
                //     }

                //     // setloading(false)
                // })();

                const initialClass = Object.keys(tableNames)[0];
                handleTabChange(initialClass)
                setProjectDetails(formData)
            }
            // data['CashFlowData'] = res
            // await AddOrUpdateCashFlowProject(data)
            // setloading(false)
        })();
    }, []);



    const UpdateInputs = async () => {
        if (inputData.length >= 0) {
            // Fetch list from the database
            // const res = await getListFromDatabase('Project_Inputs');

            console.log('Input Data:', inputData.length);

            const count = formData['Pipelines_count']; // Desired number of pipelines
            console.log('Input Data:', inputData.length);
            let updatedData = inputData.map((item) => {
                // Count existing pipeline columns dynamically
                const existingPipelineCount = Object.keys(item).filter(key => key.startsWith("Revenue_")).length;

                // If existing count matches the required count, return the item as-is
                if (existingPipelineCount === count) return item;

                // Otherwise, update missing pipeline columns
                let updatedItem = { ...item };
                for (let index = existingPipelineCount; index < count; index++) {
                    updatedItem[`Revenue_${index + 1}`] = item[`Revenue_1`] || 0;
                    updatedItem[`Cost_${index + 1}`] = item[`Cost_1`] || 0;
                }
                return updatedItem;
            });

            setInputData(updatedData);
        }
    };

    // dashboard
    function transformData(exp, rev) {
        const result = [];

        // Iterate over entries of the expenditure object
        Object.entries(exp).forEach(([key, expenditureValue]) => {
            // Only process valid date keys
            if (/^\d{2}-[a-zA-Z]{3}-\d{4}$/.test(key)) {
                const monthName = key.slice(3);
                const expenditure = parseInt(expenditureValue) || 0;
                const cashIn = parseInt(rev[key]) || 0;
                const netCash = cashIn - expenditure;

                result.push({
                    monthName,
                    expenditure: expenditure,
                    cashIn: cashIn,
                    netCash: netCash,
                });
            }
        });

        return result;
    }

    // 
    const [themeDash, setThemeDash] = useState(localStorage.getItem("webTheme") || "dark");

    useEffect(() => {
        const checkTheme = setInterval(() => {
            const currentTheme = localStorage.getItem('webTheme');
            setThemeDash(currentTheme || 'dark');
        }, 500);

        return () => clearInterval(checkTheme);
    }, []);

    useEffect(() => {
        if (cashReceipts.length) {
            const exp = expenditureData[expenditureData.length - 1];
            const rev = cashReceipts[cashReceipts.length - 2];
            const finalArray = transformData(exp, rev);
            //
            const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const sortedData = finalArray.sort((a, b) => {
                const [monthA, yearA] = a.monthName.split('-');
                const [monthB, yearB] = b.monthName.split('-');
                // Compare by year first, then by month
                if (yearA !== yearB) {
                    return yearA - yearB;
                }
                return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
            });
            setDashBoardCashReceipts(
                {
                    title: {
                        // text: "CRC Evans cashflow by Product Category ",
                        text: "Cumulative cashflow in U.S. Dollars ",
                        color: themeDash !== 'light' ? '#ffffff' : '#000000',
                    },
                    // subtitle: {
                    //     text: "Cumulative cashflow in U.S. Dollars",
                    // },
                    data: sortedData,
                    series: [
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "expenditure",
                            yName: "Total Expenditure in $",
                        },
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "cashIn",
                            yName: "Total Cash Received in $",
                        },
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "netCash",
                            yName: "Total Cash Flow in $",
                        },
                    ],
                    axes: [
                        {
                            type: 'category',
                            position: 'bottom',
                            label: {
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                        {
                            type: 'number',
                            position: 'left',
                            label: {
                                formatter: (params) => {
                                    return params.value.toLocaleString('en-US');
                                },
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                    ],
                    legend: {
                        position: 'bottom',
                        item: {
                            label: {
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                    },
                    background: {
                        fill: isRevFullScreen ? themeDash !== 'light' ? '#180204' : '#ffffff' : themeDash !== 'light' ? '#180204' : '#ffffff',
                    },
                }
            )
        }
        if (expenditureData.length) {
            const exp = expenditureData[expenditureData.length - 1];
            const rev = cashReceipts[cashReceipts.length - 2];
            const finalArray = transformData(exp, rev);
            //
            const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const sortedData = finalArray.sort((a, b) => {
                const [monthA, yearA] = a.monthName.split('-');
                const [monthB, yearB] = b.monthName.split('-');
                // Compare by year first, then by month
                if (yearA !== yearB) {
                    return yearA - yearB;
                }
                return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
            });
            setDashBoardExpenditureData(
                {
                    title: {
                        // text: "CRC Evans Expenditure & Cash In by Product Category ",
                        text: "Total Expenditure & Cash In in U.S. Dollars",
                        color: themeDash !== 'light' ? '#ffffff' : '#000000',
                    },
                    // subtitle: {
                    //     text: "Total Expenditure & Cash In in U.S. Dollars",
                    // },
                    data: sortedData,
                    series: [
                        {
                            type: "bar",
                            xKey: "monthName",
                            yKey: "expenditure",
                            yName: "Total Expenditure in $",
                        },
                        {
                            type: "bar",
                            xKey: "monthName",
                            yKey: "cashIn",
                            yName: "Total Cash Received in $",
                        },
                    ],
                    axes: [
                        {
                            type: 'category',
                            position: 'bottom',
                            label: {
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                        {
                            type: 'number',
                            position: 'left',
                            label: {
                                formatter: (params) => {
                                    return params.value.toLocaleString('en-US');
                                },
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                    ],
                    legend: {
                        position: 'bottom',
                        item: {
                            label: {
                                color: themeDash !== 'light' ? '#ffffff' : '#000000',
                            },
                        },
                    },
                    background: {
                        fill: isExpFullScreen ? themeDash !== 'light' ? '#180204' : '#ffffff' : themeDash !== 'light' ? '#180204' : '#ffffff',
                    },
                }
            )
        }
    }, [cashReceipts, expenditureData, isExpFullScreen, isRevFullScreen, themeDash]);

    //export image
    const downloadChart = async (chartId, fileName) => {
        const chartContainer = document.getElementById(chartId);

        if (chartContainer) {
            // Hide buttons temporarily
            const buttons = chartContainer.querySelectorAll("button");
            buttons.forEach((button) => (button.style.display = "none"));

            // Capture the chart as an image
            const canvas = await html2canvas(chartContainer);
            const image = canvas.toDataURL("image/png");

            // Create a link element to download the image
            const link = document.createElement("a");
            link.href = image;
            link.download = fileName;
            link.click();

            // Restore buttons after capture
            buttons.forEach((button) => (button.style.display = ""));
        } else {
            console.error("Chart container not found.");
        }
    };





    const reorderObjectExpenture = async (obj, key) => {
        // Static keys in the desired order
        let staticKeys = ["Category", "Order", "Supplier Payment Terms", "Cash Out", 'Cost', "Total Cost USD"];
        if (key == 'cash_receipts')
            staticKeys = ["Category", "Approved Invoice", "Payment Due", "Milestones", , "Cash In", "Total Cost USD"];

        // Extract date-like keys dynamically using regex
        const dateKeys = Object.keys(obj).filter(key => /\d{2}-[A-Za-z]{3}-\d{4}/.test(key));

        // Sort the date keys chronologically
        dateKeys.sort((a, b) => new Date(a) - new Date(b));
        const sliceV = key == 'cash_receipts' ? 6 : 3
        // Combine the static keys and sorted date keys
        const orderedKeys = [...staticKeys.slice(0, sliceV), ...dateKeys, ...staticKeys.slice(sliceV)];

        // Create a new object with the desired key order
        const reorderedObj = {};
        orderedKeys.forEach((key) => {
            if (key in obj) {
                reorderedObj[key] = obj[key];
            }
        });

        return reorderedObj;
    };

    const generateColumns = async (columnList, tapName) => {


        if (tapName == 'expenditure' || tapName === 'cash_receipts') {
            columnList = await reorderObjectExpenture(columnList, tapName)
        }
        //console.log(columnList)
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'ID') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly

                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                    values: ["Equipment", "Welding Wire", "Consumables & Spares", "Shipping Mob/Demob", "PM", "Commission"],
                },


                valueFormatter: (params) => {
                    if (tapName == 'expenditure' || tapName === 'cash_receipts') {


                        // Check if the column is a date column
                        const isDateColumn = ['Approved Invoice', 'Payment Due'].includes(key);

                        if (isDateColumn) {
                            // Validate and format the date
                            const date = moment(params.value); // Parse date with moment.js
                            return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                        }
                        else if (['Milestones'].includes(key)) {
                            const foramteValue = Math.floor(Number(params.value)).toString();
                            return `${foramteValue}%`
                        }

                        else {

                            const isDateColumn1 = ['Category', 'Order', 'Supplier Payment Terms', 'Payment_Due', 'Approved_Invoice'].includes(key);

                            if (!isDateColumn1) {
                                const foramteValue = Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return `$${foramteValue}`

                            }
                        }
                        return params.value;
                    }
                    else if (tapName == 'INPUT') {
                        if (['Revenue_1', 'Cost_1'].includes(key)) {
                            const foramteValue = Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return `$${foramteValue}`

                        }
                    }


                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: { textAlign: 'left' }, // Cell alignment
                //editable: true, // Enable editing for cells
                //filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));

        // Debugging output for generated columns
        //console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };



    const fetchData = async (data, key) => {
        console.log(data.length)
        if (data.length > 0) {


            generateColumns(data[0], key);
            setRowData(data);

            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };

    const convertToAssumptionArray = (inputObj) => {
        console.log(inputObj)
        // Map each key-value pair into the desired object format, excluding certain keys
        return Object.entries(inputObj)
            .filter(([key]) => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'InputProjectData' && key !== 'ID') // Filter out unwanted keys
            .map(([key, value]) => ({
                Assumption_Key: key.replace(/_/g, ' '), // Replace underscores with spaces
                Value: value
            }));
    };



    const handleTabChange = async (key) => {

        console.log(key)
        setloading(true)


        setActiveKeyLine(key);
        //setRowData([])
        // if (key === 'INPUT') {

        //     await UpdateInputs()
        // }
        if (key === 'expenditure' && expenditureData.length > 0) {
            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)

            await fetchData(expenditureData, key)
            setloadingData(false);

        }
        else if (key === 'cash_receipts' && cashReceipts.length > 0) {
            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)

            await fetchData(cashReceipts, key)
            setloadingData(false);

        }
        // else if (key === 'KEY_ASSUMPTION' && projectDetails) {
        //     const data = convertToAssumptionArray(projectDetails)

        //     setloadingData(true);
        //     setColumnDefs(null);
        //     setRowData(null)

        //     await fetchData(data, key)
        //     setloadingData(false);

        // }

        setSelectedMatrialClass(key);
        setloading(false)
    }




    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };





    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
            filter: true,
        };
    }, []);


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log("params", params.api)
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawListLoading, loadingData, activeKeyLine]);



    useEffect(() => {
        if (isRawListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (isRawListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);


    const OnGenerateCashFlow = async () => {
       setIsGenerateLoading(true)
        formData['InputProjectData'] = inputData
        const data = {
            'Project_details': formData,
        }
       // console.log('ProjectData', data)

        const response = await generateEMEAASales(data)
        formData['CashFlowData'] = response

        if (formData['CashFlowData'].expenditure)
            setExpenditureData(formData['CashFlowData'].expenditure)
        if (formData['CashFlowData'].cash_receipts)
            setCashReceipts(formData['CashFlowData'].cash_receipts)

    
        const res = await AddOrUpdateCashFlowProject(formData)
        if (res && res.length > 0) {
           // console.log(res)
            setProjectDetails(res[0])
            setFormData(res[0])
        }
       setIsGenerateLoading(false)
    }

    // const saveBase64AsPng = (base64String, fileName) => {
    //     const byteCharacters = atob(base64String);
    //     const byteNumbers = new Array(byteCharacters.length);

    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }

    //     const byteArray = new Uint8Array(byteNumbers);
    //     const blob = new Blob([byteArray], { type: "image/png" });

    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = fileName;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // const OnGenerateCashFlowReport1 = async () => {
    //     try {
    //         // Generate Expenditure Chart Image
    //         const expenditureChartOptions = { ...dashBoardExpenditureData };
    //         const expenditureChart = AgChart.create(expenditureChartOptions);
    //         const expenditureImage = await AgChart.download(expenditureChart, { download: false });
    //         const expenditureBase64 = expenditureImage.replace(/^data:image\/\w+;base64,/, '');

    //         // Generate Cash Receipts Chart Image
    //         const cashReceiptsChartOptions = { ...dashBoardCashReceipts };
    //         const cashReceiptsChart = AgChart.create(cashReceiptsChartOptions);
    //         const cashReceiptsImage = await AgChart.download(cashReceiptsChart, { download: false });
    //         const cashReceiptsBase64 = cashReceiptsImage.replace(/^data:image\/\w+;base64,/, '');

    //         // Save both images locally as PNG files
    //         saveBase64AsPng(expenditureBase64, "ExpenditureChart.png");
    //         saveBase64AsPng(cashReceiptsBase64, "CashReceiptsChart.png");

    //     } catch (error) {
    //         console.error("Error generating chart images:", error);
    //     }
    // };

    const OnGenerateCashFlowReport = async () => {

       setGenerateReportLoading(true)
      const  expenditure_Image = await getGrapghImage('expenditure-chart')
      const cashreceipts_Image = await getGrapghImage('cash-receipts-chart')
        // console.log('ProjectData', projectDetails)
        // console.log('expenditure_Image', expenditure_Image)
        // console.log('cashreceipts_Image', cashreceipts_Image)
        projectDetails['Report_Url'] = {
            report_url:'',
            expenditure_Image:expenditure_Image,
            cashreceipts_Image: cashreceipts_Image,
        }
        const urlReport = await GenerateCashflowExcelReport(projectDetails)
        //console.log(urlReport)

        if (urlReport && urlReport.s3Url) {

            projectDetails['Report_Url'] = {
                report_url: urlReport.s3Url,
                expenditure_Image: expenditure_Image,
                cashreceipts_Image: cashreceipts_Image
            }

            await AddOrUpdateReportUrl(projectDetails, isEdit)
            if (urlReport.s3Url) {
                //setReportUrl(urlReport.s3Url)
                const link = document.createElement('a');
                link.href = urlReport.s3Url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setProjectDetails(projectDetails)
                // projectDetails.Report_Url = urlData.Report_Url

            }
        }

        setGenerateReportLoading(false)
        //console.log(urlReport)
    }



    // Custom status panel component
    const CustomStatusPanel = () => {
        return (
            <>

                <div style={{ paddingTop: '10px' }}>
                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '-10px', marginBottom: '-20px' }}
                        onClick={async () => await addEmptyRow()} disabled={activeKeyLine == 'INPUT' ? false : true}
                    >
                        Add New
                    </Button>

                    <Button shape="round" key="apply" type="primary" style={{ paddingLeft: '10px', marginBottom: '-20px' }}
                        onClick={async () => await OnGenerateCashFlow()} disabled={activeKeyLine == 'INPUT' ? false : true}
                    >
                        Generate Cash Flow
                    </Button>

                </div>

            </>
        )

    };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            // {
            //     statusPanel: () => <CustomStatusPanel />, // React component
            //     align: 'right',
            // },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);


    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };


    // Function to Add an Empty Row
    const addEmptyRow = async () => {

        const newRow = {
            'ID': gridRef.current.api.getDisplayedRowCount() + 1, // Get index of the last row,
            "Section": "",
            "Category": "",
            "Type": "",
            "Revenue_1": 0,
            "Cost_1": 0
        }; // Define the empty row
        console.log('Emty Row', newRow)
        const transaction = { add: [newRow] }; // Create a row transaction
        gridRef.current.api.applyTransaction(transaction); // Apply the transaction
        inputData.push(newRow)
        setInputData(inputData)
        setRowData(inputData)
        gridApi.showLoadingOverlay();
        // Optionally, scroll to the new row
        setTimeout(() => {
            const rowIndex = gridRef.current.api.getDisplayedRowCount() - 1; // Get index of the last row
            gridRef.current.api.ensureIndexVisible(rowIndex, "bottom"); // Scroll to the new row
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 50);


    }

    const removeRowById = async (targetId) => {
        // Ensure gridApi and gridRef exist
        if (!gridApi || !gridRef.current) {
            console.error("Grid API or reference is not available.");
            return;
        }

        // Clone the inputData array to avoid direct mutation
        const updatedData = [...inputData];
        console.log(updatedData)
        // Find the index of the row with the target ID
        const index = updatedData.findIndex(row => row.ID === targetId);

        // Check if the ID exists in the data
        if (index === -1) {
            console.warn(`Row with ID ${targetId} not found.`);
            return;
        }

        // Remove the row at the found index
        updatedData.splice(index, 1);

        // Reorder IDs sequentially
        const reorderedData = updatedData.map((row, idx) => ({
            ...row,
            id: idx + 1, // Assign sequential IDs starting from 1
        }));

        // Update the state with the new data
        setInputData(reorderedData);
        setRowData(reorderedData);

        // Refresh the grid
        gridApi.showLoadingOverlay();
        setTimeout(() => {
            //gridRef.current.api.setRowData(reorderedData); // Update grid data
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 100); // Delay to simulate loading effect
    }
    //dashboard
    const toggleRevFullScreen = () => {
        setIsRevFullScreen((prev) => !prev);
    };
    const toggleExpFullScreen = () => {
        setIsExpFullScreen((prev) => !prev);
    };


    const getPipelineTabpanes = () => {


        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>
                <Tabs activeKey={activeKeyLine} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames)
                        .filter(key =>
                            (key !== 'expenditure' || (key === 'expenditure' && expenditureData?.length > 0)) &&
                            (key !== 'cash_receipts' || (key === 'cash_receipts' && cashReceipts?.length > 0)) &&
                            (key !== 'dashBoard' || (key === 'dashBoard' && cashReceipts?.length > 0))
                        )
                        .map((row) => (
                            <TabPane tab={tableNames[row]} key={row}>
                                {row !== 'dashBoard' ?
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            {row === 'INPUT' && row != 'KEY_ASSUMPTION' ?
                                                <ProjectsInputs
                                                    setProjectDetails={setProjectDetails}
                                                    projectDetails={projectDetails}
                                                    inputData={inputData}
                                                    setInputData={setInputData}
                                                    OnGenerateCashFlow={OnGenerateCashFlow}
                                                ></ProjectsInputs> :


                                                formData && projectDetails && row == 'KEY_ASSUMPTION' && row !== 'INPUT' ?

                                                    <ProjectDetailsAgGrid
                                                        setProjectDetails={setProjectDetails}
                                                        projectDetails={projectDetails}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        isEdit={isEdit}
                                                        setIsEdit={setIsEdit}
                                                        OnGenerateCashFlow={OnGenerateCashFlow}
                                                        inputData={inputData}
                                                        setInputData={setInputData}
                                                        isGenerateLoading={isGenerateLoading}
                                                    >

                                                    </ProjectDetailsAgGrid>
                                                    :

                                                    <div style={{ height: 'calc(100vh - 185px)', overflow: 'auto', position: 'relative' }}>
                                                        <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                                                            <AgGridReact
                                                                ref={gridRef}
                                                                rowData={rowData}
                                                                columnDefs={columnDefs}
                                                                rowDragManaged={true}
                                                                onRowDragEnd={onRowDragEnd}
                                                                defaultColDef={defaultColDef}
                                                                rowSelection={rowSelection}
                                                                onGridReady={onGridReady}
                                                                sideBar={false}
                                                                hiddenByDefault={true}
                                                                pivotMode={false}

                                                                pivotPanelShow={"never"}
                                                                autoGroupColumnDef={autoGroupColumnDef}
                                                                animateRows={true}
                                                                onSelectionChanged={onSelectionChanged}
                                                                enableFilter={true}
                                                                statusBar={statusBar}
                                                                rowHeight={40}
                                                                headerHeight={50}
                                                                defaultExcelExportParams={defaultExcelExportParams}
                                                                defaultCsvExportParams={defaultCsvExportParams}
                                                                loadingOverlayComponent={CustomLoadingCellRenderer}
                                                                loadingOverlayComponentParams={{
                                                                    loadingMessage: "Loading all rows...",
                                                                }}
                                                            // onCellValueChanged={onCellValueChanged}

                                                            />

                                                        </div>
                                                    </div>
                                            }


                                            {/* {row == 'KEY_ASSUMPTION' &&
                                            getKeyAssumption()
                                        } */}
                                        </Col>

                                    </Row>
                                    :
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div className='chart-dashboard'>
                                                <div id="expenditure-chart" className={`chart-container ${isExpFullScreen ? "full-screen" : ""}`}>
                                                    <div>
                                                        <button
                                                            onClick={() => downloadChart("expenditure-chart", "ExpenditureChart.png")}
                                                            className={` ${!isRevFullScreen ? "chart-button" : ""}`}
                                                        >
                                                            <DownloadOutlined />
                                                        </button>
                                                        <button onClick={toggleExpFullScreen} className={` ${!isRevFullScreen ? "toggle-button" : ""}`}>
                                                            {isExpFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
                                                        </button>
                                                    </div>
                                                    <AgCharts options={dashBoardExpenditureData} className={`chart-container ${isExpFullScreen ? "full-width" : ""}`} />
                                                </div>
                                                <hr />
                                                <div id="cash-receipts-chart" className={`chart-container ${isRevFullScreen ? "full-screen" : ""}`}>
                                                    <div>
                                                        <button
                                                            onClick={() => downloadChart("cash-receipts-chart", "CashReceiptsChart.png")}
                                                            className={` ${!isExpFullScreen ? "chart-button" : ""}`}
                                                        >
                                                            <DownloadOutlined />
                                                        </button>
                                                        <button onClick={toggleRevFullScreen} className={`${!isExpFullScreen ? "toggle-button" : ""}`}>
                                                            {isRevFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
                                                        </button>
                                                    </div>
                                                    <AgCharts options={dashBoardCashReceipts} className={`chart-container ${isRevFullScreen ? "full-width" : ""}`} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        ))}
                </Tabs>
            </div>
        );
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        if (activeKeyLine == 'INPUT' && selectedData) {
            setIsDeleteLoading(true)
            // const obj = {
            //     columnName: 'Project_Inputs',
            //     tableName: 'Project_Inputs',
            //     data: selectedData
            // };
            // console.log(obj);
            // await DeleteDetails(obj)
            await removeRowById(selectedData.ID)
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);


        }

    }
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }

    const captureChart = async () => {


        // Hide buttons temporarily
        // const buttons = chartContainer.querySelectorAll("button");
        // buttons.forEach((button) => (button.style.display = "none"));
        // <div className='chart-dashboard'>
        //     <div id="expenditure-chart" className={`chart-container ${isExpFullScreen ? "full-screen" : ""}`}>
        //         <div>
        //             <button
        //                 onClick={() => downloadChart("expenditure-chart", "ExpenditureChart.png")}
        //                 className={` ${!isRevFullScreen ? "chart-button" : ""}`}
        //             >
        //                 <DownloadOutlined />
        //             </button>
        //             <button onClick={toggleExpFullScreen} className={` ${!isRevFullScreen ? "toggle-button" : ""}`}>
        //                 {isExpFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
        //             </button>
        //         </div>
        //         <AgCharts options={dashBoardExpenditureData} className={`chart-container ${isExpFullScreen ? "full-width" : ""}`} />
        //     </div>
        //     <hr />
        //     <div id="cash-receipts-chart" className={`chart-container ${isRevFullScreen ? "full-screen" : ""}`}>
        //         <div>
        //             <button
        //                 onClick={() => downloadChart("cash-receipts-chart", "CashReceiptsChart.png")}
        //                 className={` ${!isExpFullScreen ? "chart-button" : ""}`}
        //             >
        //                 <DownloadOutlined />
        //             </button>
        //             <button onClick={toggleRevFullScreen} className={`${!isExpFullScreen ? "toggle-button" : ""}`}>
        //                 {isRevFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
        //             </button>
        //         </div>
        //         <AgCharts options={dashBoardCashReceipts} className={`chart-container ${isRevFullScreen ? "full-width" : ""}`} />
        //     </div>
        // </div>

        // document.createElement(<div id="cash-receipts-chart1" >
        //     <AgCharts options={dashBoardCashReceipts} className={`chart-container ${isRevFullScreen ? "full-width" : ""}`} />
        // </div>)
        const chartContainer = document.getElementById('expenditure-chart');
         // Hide buttons temporarily
        const buttons = chartContainer.querySelectorAll("button");
        buttons.forEach((button) => (button.style.display = "none"));
        if (chartContainer) {
            // Capture the chart as an image
            const canvas = await html2canvas(chartContainer);
            const image = canvas.toDataURL("image/png");
            // Update the image data in `OngenrateSignedPdf`
            const base64Image = canvas.toDataURL().replace(/^data:image\/\w+;base64,/, '');
            console.log('Image', base64Image)
            const link = document.createElement("a");
            link.href = image;
            link.download = 'expenditure-chart.png';
            link.click();

            //Restore buttons after capture
            buttons.forEach((button) => (button.style.display = ""));
        }
        return;
        // const cashreceiptschart = document.getElementById('cash-receipts-chart');
        // if (chartContainer) {
        //     // Capture the chart as an image
        //     const canvas = await html2canvas(chartContainer);
        //     const image = canvas.toDataURL("image/png");
        //     // Update the image data in `OngenrateSignedPdf`
        //     const base64Image = canvas.toDataURL().replace(/^data:image\/\w+;base64,/, '');
        //     console.log('Image', base64Image)
        //     const link = document.createElement("a");
        //     link.href = image;
        //     link.download = 'cash-receipts-chart.png';
        //     link.click();

        //     // Restore buttons after capture
        //     buttons.forEach((button) => (button.style.display = ""));
        // }
        // return;

        // console.log(chartRef1)
        // if (chartRef1.current) {
        //     const canvas = await html2canvas(chartRef1.current);
        //     const image = canvas.toDataURL("image/png");
        //     const base64Image = image.replace(/^data:image\/\w+;base64,/, '');

        //     console.log("Image Base64:", base64Image);

        //     // Download image
        //     const link = document.createElement("a");
        //     link.href = image;
        //     link.download = 'sella.png';
        //     link.click();
        // }
    };


    const getGrapghImage = async(graphName)=>{
        const chartContainer = document.getElementById(graphName);
        // Hide buttons temporarily
       const buttons = chartContainer.querySelectorAll("button");
       buttons.forEach((button) => (button.style.display = "none"));
       let base64Image = ''
       if (chartContainer) {
           // Capture the chart as an image
           const canvas = await html2canvas(chartContainer);
           //const image = canvas.toDataURL("image/png");
           // Update the image data in `OngenrateSignedPdf`
            base64Image = canvas.toDataURL().replace(/^data:image\/\w+;base64,/, '');
           console.log('Image', base64Image)
        //    const link = document.createElement("a");
        //    link.href = image;
        //    link.download = 'expenditure-chart.png';
        //    link.click();

           //Restore buttons after capture
           buttons.forEach((button) => (button.style.display = ""));
       }
       return base64Image;
    }


    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setShowPipelineModel(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    Cash Flow
                </Title>

                <div className="new-calibration" style={{ display: 'flex', alignItems: 'center' }}>


                    {projectDetails && projectDetails.Report_Url?.report_url &&
                        <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                            href={projectDetails.Report_Url?.report_url}
                        >
                            <DownloadOutlined style={{ fontSize: '20px' }}> </DownloadOutlined>
                            Report
                        </Button>
                    }
                    {
                        projectDetails?.CashFlowData?.expenditure && activeKeyLine ==='dashBoard' &&
                        <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                            onClick={() => { 
                                //captureChart();
                                OnGenerateCashFlowReport() 
                                }} loading={GenerateReportLoading}>
                            Generate Report
                        </Button>
                    }

                </div>

            </div>

            {getPipelineTabpanes()}

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

            {/* {projectDetails?.CashFlowData?.expenditure && 
            <div style={{ position: "absolute", left: "-9999px", visibility: "hidden" }}>
             
                <div  useRef ={chartRef1} id="expenditure-chart1" className={`chart-container full-screen`}>

                    <AgCharts options={dashBoardExpenditureData} className={`chart-container full-width`} />
                </div>
                <div  useRef ={chartRef2} id="cash-receipts-chart1" className={`chart-container full-screen`}>

                    <AgCharts options={dashBoardCashReceipts} className={`chart-container full-width}`} />
                </div>
            </div>
            } */}

        </div>

    );


};
const mapStateToProps = ({ FinanceMappingReducer }) => {
    const isRawListLoading = get(FinanceMappingReducer, 'isFetchLoading', false);
    const rawDataList = get(FinanceMappingReducer, `rawDataList`, {}); // Fetch specific table data

    return {
        isRawListLoading,
        rawDataList,
    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTaps);